import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../admin/common/services/auth.service';
import { IProduct } from '../models/product';
import { catchError, map, publishReplay, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Utils } from 'src/app/shared/utils';
import { IProductGroup } from '../models/product-group';
import { Constants } from 'src/app/shared/constants';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import { IGroupImage } from '../models/group-image';
import { IProductImage } from '../models/product-image';
import { IProductDetails } from '../models/product-details';
import { IProductLocation } from '../models/product-location';
import { MatTableDataSource } from '@angular/material/table';
import { IConfigurableTable } from '../models/product-configurables';
import { IAnalysisTable } from '../models/product-analysis';
import { ProductConfig } from '../models/product-with-config';
import { ProductAnalysis } from '../models/product-with-analysis';
import { NotificationService } from 'src/app/shared/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  private cp: number = 1;  // Default to page 1
  private p: number = 12;  // Default to 12 items per page

  constructor(private _http: HttpClient, private _authService: AuthService , private _notifyService: NotificationService
    ) {}

  // #region API call

  // getProducts(): Observable<IProduct[]> {
  //   return from(this.authService.getAccessToken().then(token => {
  //     const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //     return this.http.get<IProduct[]>(Constants.apiRoot + 'Suppliers', { headers: headers }).toPromise();
  //   }));
  // }

  getProducts(): Observable<IProduct[]> {
    return from(this._authService.getAccessToken().then(token => {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      return this._http.get<IProduct[]>(Constants.apiRoot + 'Suppliers', { headers: headers }).toPromise();
    }));
  }

  // #endregion

  // #region in memeory API

  // #region fields
  // this is for the in memory api if you need it.
  // private localProductsUrl = 'api/products';
  // private localProductGroupsUrl = 'api/productGroups';

  private productsUrl = 'stock/productsbycustomerid/navsa';
  private productGroupsUrl = 'stock/productgroups';
  private productGroupImagesUrl = 'stock/productgroupimages';
  private productDetailsUrl = 'stock/spec/navsa';
  private productImagesUrl = 'stock/productwebimages';
  private productLocationsUrl = 'stock/stocklevels/locations';
  private productConfigurablesUrl = 'stock/configurables';
  private productWithConfigUrl = 'stock/productsWithconfigurables';
  private productAnalysisUrl = 'stock/analysistables';
  private productWithAnalysisUrl = 'stock/productswithanalysis';
  // #endregion

  //#region caching fields

  //#endregion

  // #region API Calls
  filteredProductsSubject1: BehaviorSubject<IProduct[]> = new BehaviorSubject<IProduct[]>([]);
  mappedProductsStore = new BehaviorSubject<IProduct[]>(new Array<IProduct>());

  productWithConfigStore = new BehaviorSubject<ProductConfig[]>(
    new Array<ProductConfig>()
  );
  productWithAnalysisStore = new BehaviorSubject<ProductAnalysis[]>(
    new Array<ProductAnalysis>()
  );

  
  filteredProductsSubject = new BehaviorSubject<number[]>(new Array<number>());
  filteredProductsAction$ = this.filteredProductsSubject.asObservable();

  totalfilteredProductsSubject : BehaviorSubject<number> = new BehaviorSubject<number>(null);
  totalfilteredProductsAction$ = this.totalfilteredProductsSubject.asObservable();

  groupFilterProducts: number[] = [];
  priceFilterProducts: number[] = [];
  configFilterProducts: number[] = [];
  analysisFilterProducts: number[] = [];

  selectedTypesSubject = new BehaviorSubject<IProductGroup[]>(
    new Array<IProductGroup>()
  );
  selectedtypesAction$ = this.selectedTypesSubject.asObservable();

  selectedConfigsSubject = new BehaviorSubject<IConfigurableTable[]>(
    new Array<IConfigurableTable>()
  );
  selectedConfigsAction$ = this.selectedConfigsSubject.asObservable();

  selectedAnalysisSubject = new BehaviorSubject<IAnalysisTable[]>(
    new Array<IAnalysisTable>()
  );
  selectedAnalysisAction$ = this.selectedAnalysisSubject.asObservable();

  basketSubject = new BehaviorSubject<IProduct[]>(new Array<IProduct>());
  basketAction$ = this.basketSubject.asObservable();

  setPage(page: number) {
    this.cp = page;
  }

  setPageSize(pageSize: number) {
    this.p = pageSize;
  }

  // products$(page: number = this.cp, pageSize: number = this.p, groupIds?: string[]): Observable<any> {
  //   return this._authService.idAccountAction$.pipe(
  //     switchMap((idAccount) => {
  //       let url = `${Constants.apiRoot}${this.productsUrl}/${idAccount !== 0 ? idAccount : ''}${idAccount}/${page}/${pageSize}`;
  //       if (groupIds && groupIds.length > 0) {
  //         url += `?groupIds=${groupIds.join(',')}`; 
  //       }
  //       return this._http.get<IProduct[]>(url).pipe(
  //         map((response: any) => ({
  //           products: response.products.map((prod: IProduct) => ({
  //             ...prod,
  //             image: Constants.images.concat(prod.image.split('\\').join('/'))
  //           })),
  //           totalItems: response.totalItems,
  //         })),
  //         catchError((error) => {
  //           console.error(error);
  
  //           // Handle 404 errors specifically
  //           if (error.status === 404) {
  //             return of({ products: [], totalItems: 0 });
  //           }
  
  //           return EMPTY; 
  //         })
  //       );
  //     })
  //   );
  // }
  
  products$(
    page: number = this.cp,
    pageSize: number = this.p,
    groupIds?: string[],
    minPrice?: number,
    maxPrice?: number
  ): Observable<any> {
    return this._authService.idAccountAction$.pipe(
      switchMap((idAccount) => {
        // Base URL for the API endpoint
        let url = `${Constants.apiRoot}${this.productsUrl}/${idAccount || 0}/${page}/${pageSize}`;


  
        // Prepare query parameters
        const params: any = {};
        if (groupIds && groupIds.length > 0) {
          params.groupIds = groupIds.join(',');
        }
        if (minPrice !== undefined && !isNaN(minPrice)) {
          params.minPrice = minPrice;
        }
        if (maxPrice !== undefined && !isNaN(maxPrice)) {
          params.maxPrice = maxPrice;
        }
  
        // Append query string if parameters exist
        const queryString = new URLSearchParams(params).toString();
        if (queryString) {
          url += `?${queryString}`;
        }
  
        console.log('Constructed URL:', url); // Debug log to verify URL construction
  
        // Make HTTP GET request
        return this._http.get<IProduct[]>(url).pipe(
          map((response: any) => ({
            products: response.products.map((prod: IProduct) => ({
              ...prod,
              image: Constants.images.concat(prod.image.split('\\').join('/'))
            })),
            totalItems: response.totalItems,
          })),
          catchError((error) => {
            console.error('Error in products$:', error);
  
            // Handle 404 errors specifically
            if (error.status === 404) {
              return of({ products: [], totalItems: 0 });
            }
  
            return EMPTY;
          })
        );
      })
    );
  }
  

  productsWithGroups$ = combineLatest([
    this.products$(this.cp, this.p ), 
    this.filteredProductsAction$,  
  ]).pipe(
    map(([response, productFilter]) => {
      let products = response.products;

      let prods = products.map(prod => ({
        ...prod,
        image: Constants.images.concat(prod.image.split('\\').join('/')),
        selectedQty: this.basketSubject?.value.length > 0
          ? this.basketSubject.value.find(x => x.aliasId === prod.aliasId)?.selectedQty ?? 0
          : 0,
        cartQty: this.basketSubject?.value.length > 0
          ? this.basketSubject.value.find(x => x.aliasId === prod.aliasId)?.cartQty ?? 0
          : 0,
        inBasket: this.basketSubject.value.length > 0
          ? this.basketSubject.value.some(x => x.aliasId === prod.aliasId)
          : false
      }) as IProduct);
      if (this.mappedProductsStore.value.length === 0) {
        this.mappedProductsStore.next(prods);
        if (this.selectedTypesSubject.value.length > 0) {
          let filteredGroups = this.selectedTypesSubject.value;
          let groupIds: string[] = [];
          let filteredProducts: number[] = [];

          filteredGroups.forEach(g => {
            if (g.isChecked) groupIds.push(g.groupId);
            g.subCategories?.forEach(s => {
              if (s.isChecked) groupIds.push(s.groupId);
            });
          });

          products
            .filter(p => groupIds.includes(p.groupId))
            .forEach(prod => filteredProducts.push(prod.id));

          this.groupFilterProducts = filteredProducts;

          prods = prods.filter(product =>
            filteredProducts.includes(product.id)
          );
        }
      } else {
        if (productFilter.length > 0)
          products = products.filter(product =>
            productFilter.includes(product.id)
          );
        prods = products.map(prod => ({
          ...prod,
          image: Constants.images.concat(prod.image.split('\\').join('/'))
        }) as IProduct);
      }

      return prods;
    }),
    shareReplay(1),
    catchError(Utils.handleError)
  );

  configurables$ = this._http
    .get<IConfigurableTable[]>(Constants.apiRoot + this.productConfigurablesUrl)
    .pipe(shareReplay(1), catchError(Utils.handleError));

  analysis$ = this._http
    .get<IAnalysisTable[]>(Constants.apiRoot + this.productAnalysisUrl)
    .pipe(shareReplay(1), catchError(Utils.handleError));

  getProductWithConfig = this._http
    .get<ProductConfig[]>(Constants.apiRoot + this.productWithConfigUrl)
    .pipe(catchError(Utils.handleError))
    .subscribe((data) => this.productWithConfigStore.next(data));

  getProductWithAnalysis$ = this._http
    .get<ProductAnalysis[]>(Constants.apiRoot + this.productWithAnalysisUrl+`/${null}`)
    .pipe(shareReplay(1), catchError(Utils.handleError))
    .subscribe((data) => this.productWithAnalysisStore.next(data));

  getProductDetails(stockId: string): Observable<IProductDetails> {
    return this._http.get<IProductDetails>(
      Constants.apiRoot + this.productDetailsUrl + `/${stockId}`
    );
  }
  
  productDetails$ = this._http
    .get<IProductDetails[]>(Constants.apiRoot + this.productDetailsUrl)
    .pipe(
      
      
      catchError(Utils.handleError));

  productGroups$ = this._http
    .get<IProductGroup[]>(Constants.apiRoot + this.productGroupsUrl)
    .pipe(
      map((groups) => {
        return groups
          .filter((g) => !g.isSubGroup)
          .map((group) => ({
            ...group,
            subCategories: groups
              .filter((g) => g.groupCode === group.groupCode && g.isSubGroup)
              .map((cat) => ({
                groupId: cat.groupId,
                groupCode: cat.groupCode,
                subCode: cat.subCode,
                groupName: cat.groupName,
                isChecked: false,
              })),
          }));
      }),
      shareReplay(1),
      catchError(Utils.handleError)
    );

  productGroupImages$ = this._http
    .get<IGroupImage[]>(Constants.apiRoot + this.productGroupImagesUrl)
    .pipe(shareReplay(1), catchError(Utils.handleError));

  getProductImags(stockId: string): Promise<IProductImage[]> {
    return this._http
      .get<IProductImage[]>(
        Constants.apiRoot + this.productImagesUrl + `/'${stockId}'`
      )
      .toPromise();
  }

  getLocationAvailability(stockId: string): Promise<IProductLocation[]> {
    return this._http
      .get<IProductLocation[]>(
        Constants.apiRoot + this.productLocationsUrl + `/'${stockId}'`
      )
      .toPromise();
  }

  combinedProduct$= combineLatest([
    this.productDetails$,
  this.productsWithGroups$

  ]).pipe(
    map(([productsDetails,product])=>
    {
      console.log(productsDetails,'show error')
      const productDetailsArray = Object.values(productsDetails);
      return productDetailsArray.map((productDetails)=>

      ({
        ...productDetails,
        price: product.find(id=>id.aliasId === productDetails.stockId)?.sellPrice,


      } as IProductDetails)
      
      
      
      )
    }
   

    )

  )
  // #endregion

  //#region Helper functions
  addToCart(product: IProduct) {
    if (!product.inBasket && !product.selectedQty) {
      this._notifyService.showError(
        'Please add quantity first',
        'Product List'
      );
      return;
    }

    if (product.image.trim().slice(-1) === '/')
      product.image = (product.image + 'assets/images/Navsa_PH.png');
      console.log(product,'test')

    let basket = this.basketSubject.value;
    let productIndex = basket.findIndex(
      (item) => item.aliasId === product.aliasId
    );

    if (productIndex > -1)
      if (product.selectedQty === 0) basket = basket.splice(productIndex);
      else {
        basket[productIndex].selectedQty = product.selectedQty;
        basket[productIndex].cartQty = product.cartQty;
      }
    else {
      this.getProductImags(product.stockId).then(
        (images) =>
          (product.imageCollection = images.map((img) => ({
            ...img,
            imageFileName:
              Constants.images + img.imageFileName.split('\\').join('/'),
          })))
      );
      this.getLocationAvailability(product.stockId).then(
        (levels) => (product.locations = levels)
      );

      basket.push(product);
    }

    this.basketSubject.next(basket);
  }

  // #endregion
}

import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthService } from './admin/common/services/auth.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { ProductService } from './products/common/services/product.service';
import {
  leftSlideInAnimation,
  zoomInAnimation,
} from './shared/animations/app-animation';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { IProductGroup } from './products/common/models/product-group';
import { AdminService } from './admin/common/services/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [leftSlideInAnimation, zoomInAnimation],
})
export class AppComponent implements OnInit {
  //#region Fields and properties
  isDarkTheme: boolean;

  isLoggedIn = true; //TBC change to false
  errorMessage: string;
  zoomState: string = 'inactive';
  productCategories$ = this._productService.productGroups$;

  //productCategories$: Observable<IProductGroup[]>

  get userName(): string {
    return this._authService.authContext?.userProfile?.contactName;
  }
  //#endregion

  constructor(
    private _authService: AuthService,
    private _productService: ProductService,
    private _adminService: AdminService,
    private _router: Router
  ) {
    this._authService.loginChanged.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  //#region Helper Methods
  // To do (clear and add filters into the service bag).
  // navigate(group: IProductGroup) {
  //   debugger
  //   this._productService.selectedTypesSubject.next([]);

  //   let groups = this._productService.selectedTypesSubject.value;

  //   if (!group.isSubGroup) {
  //     let subGroups = this._productService.productGroups$.pipe(
  //       map(subGroups => subGroups.filter(sub => sub.groupCode === group.groupCode))
  //     );

  //     subGroups.subscribe(subs => subs.forEach(sub => groups.push(sub)));
  //   } else groups.push(group);

  //   this._productService.selectedTypesSubject.next(groups);
  //   this._router.navigate(['/products']);
  // }

  navigate(group: IProductGroup) {
    this._productService.selectedTypesSubject.next([]);
    let groups = this._productService.selectedTypesSubject.value;
  
    if (!group.isSubGroup) {
      this._productService.productGroups$.pipe(
        map(subGroups => subGroups.filter(sub => sub.groupCode === group.groupCode))
      ).subscribe(subs => subs.forEach(sub => groups.push(sub)));
    } else {
      groups.push(group);
    }
  
    this._productService.selectedTypesSubject.next(groups);
    this._router.navigate(['/products'], { queryParams: { groupId: group.groupId } });
  }
  

  register() {
    //window.open('https://localhost:5001/Account/Register/', '_blank');
    //window.open('https://angvm01.uksouth.cloudapp.azure.com:6018/Account/Register/', '_blank');
    window.open('https://www.navsainternational.com:6007/Account/Register/', '_blank');
  }

  login() {
    this._authService.login();
  }

  logout() {
    this._authService.logout();
  }

  isAdmin() {
    return (
      this._authService.authContext && this._authService.authContext.isAdmin
    );
  }
  //#endregion

  //#region Inerface Implementation
  ngOnInit(): void {
    this._authService.isLoggedIn().then((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    this._adminService.toggleTheme();
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }
  //#endregion
}
